<template>
  <div>
    <canvas ref="pieChart" />
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'PieChart',
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const ctx = this.$refs.pieChart.getContext('2d');
    new Chart(ctx, {
      type: 'pie',
      data: this.data,
      options: this.options,
    });
  },
};
</script>
