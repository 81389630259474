<template>
  <div class="w-full flex flex-col min-h-screen">
    <header
      class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800 py-3"
    >
      <div class="flex flex-row justify-between items-center">
        <div class="flex w-full px-4">
          <woot-sidemenu-icon class="m-0" />
          <h1
            class="m-0 ml-3 text-lg text-slate-900 dark:text-slate-100 whitespace-nowrap"
          >
            Managemen Work Order
          </h1>
        </div>
        <div class="flex w-full justify-end mr-4">
          <woot-button
            class="clear"
            color-scheme="success"
            icon="book"
            data-testid="create-new-work-order"
            @click="addWorkOrder"
          >
            Tambah Work Order
          </woot-button>
        </div>
      </div>
    </header>
    <section
      class="conversation-page flex-grow bg-white dark:bg-slate-900 overflow-auto"
    >
      <div class="gap-2 flex flex-row mt-1 mx-2">
        <div class="text-nowrap flex flex-col w-1/3">
          <multiselect
            v-model="selectedPelanggan"
            :options="pelangganList"
            track-by="pelanggan_id"
            label="full_name"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pilih Pelanggan"
            @select="onSelectedPelanggan"
            @remove="onRemovePelanggan"
            @search-change="onSearchPelanggan"
          />
        </div>
        <div class="text-nowrap flex flex-col w-1/3">
          <multiselect
            v-model="selectedStatus"
            :options="statusOptions"
            track-by="key"
            label="label"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pilih Status"
            @select="onSelectedStatus"
            @remove="onRemoveStatus"
          />
        </div>
        <div class="text-nowrap flex flex-col w-1/3">
          <Datepicker
            v-model="dateRange"
            range
            type="date"
            format="YYYY-MM-DD"
            :confirm="true"
            :not-auto-close="false"
            confirm-text="Pilih"
            placeholder="Pilih Rentang Tanggal"
            class="w-full"
            @change="onChangeRange"
          />
        </div>
      </div>
      <div class="gap-2 flex flex-row mt-1 mx-2">
        <div class="text-nowrap flex flex-col w-1/3">
          <multiselect
            v-model="staffSelected"
            :max-height="160"
            :options="staffs"
            track-by="id"
            label="full_name"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :hide-selected="true"
            placeholder="Pilih Staff"
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="onStaffChange"
            @remove="onStaffChange"
            class="custom-multiselect"
          />
        </div>
        <div class="text-nowrap flex flex-col w-1/3">
          <multiselect
            v-model="selectedWorkOrderType"
            :options="workOrder.types"
            track-by="id"
            label="type"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pilih Work Order Type"
            @select="onSelectedWorkOrderType"
            @remove="onRemoveWorkOrderType"
          />
        </div>
        <div class="text-nowrap flex flex-col w-1/3">
          <multiselect
            v-model="selectedWorkOrderMaster"
            :options="workOrder.masters"
            track-by="id"
            label="name"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pilih Work Order Master"
            @select="onSelectedWorkOrderMaster"
            @remove="onRemoveWorkOrderMaster"
          />
        </div>
      </div>
      <div
        v-if="uiFlags.isFetchingWorkOrderRecords"
        class="flex justify-center items-center text-center text-slate-900 dark:text-white py-4"
      >
        <spinner /> Sedang memuat data...
      </div>
      <div v-else>
        <div
          v-if="workOrder && workOrder.length === 0"
          class="text-center py-4 text-gray-600"
        >
          Tidak ada data work order.
        </div>
        <div v-else class="complaint-table overflow-auto">
          <ve-table
            max-height="calc(100vh - 12rem)"
            scroll-width="150rem"
            :columns="columns"
            :table-data="workOrder.records"
            :border-around="false"
          />
        </div>
      </div>
    </section>
    <footer
      v-if="isFooterVisible"
      class="bg-white dark:bg-slate-800 h-[60px] border-t border-solid border-slate-75 dark:border-slate-700/50 flex items-center justify-between py-0 px-4"
    >
      <div class="left-aligned-wrap">
        <div class="text-xs text-slate-600 dark:text-slate-200">
          <strong>{{ firstIndex }}</strong> -
          <strong>{{ lastIndex }}</strong> of
          <strong>{{ totalCount }}</strong> items
        </div>
      </div>
      <div class="right-aligned-wrap">
        <div
          v-if="totalCount > 0"
          class="primary button-group pagination-button-group"
        >
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasFirstPage"
            @click="onFirstPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
            <fluent-icon
              icon="chevron-left"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasPrevPage"
            @click="onPrevPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <woot-button size="small" variant="smooth" color-scheme="secondary">
            {{ currentPage }}
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasNextPage"
            @click="onNextPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasLastPage"
            @click="onLastPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
            <fluent-icon
              icon="chevron-right"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
        </div>
      </div>
    </footer>
    <create-work-order
      v-if="showCreateModal"
      :show="showCreateModal"
      @cancel="closeCreateModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';
import CreateWorkOrder from './CreateWorkOrder.vue';
import Spinner from 'shared/components/Spinner.vue';
import Datepicker from 'vue2-datepicker';

export default {
  data() {
    return {
      pageSize: 10,
      selectedWorkOrderType: null,
      selectedWorkOrderMaster: null,
      selectedPelanggan: null,
      pelanggan_id: null,
      showCreateModal: false,
      dateRange: [],
      tableData: [],
      staffSelected: [],
      selectedStatus: [],
      statusOptions: [
        { key: 'pending', label: 'Pending' },
        { key: 'active', label: 'Active' },
        { key: 'finish', label: 'Finish' },
        { key: 'cancel', label: 'Cancel' },
        { key: 'validated', label: 'Validated' },
      ],
    };
  },
  components: {
    CreateWorkOrder,
    VeTable,
    Spinner,
    Datepicker,
  },
  computed: {
    ...mapGetters({
      meta: 'openaccess/getWorkOrderMeta',
      token: 'openaccess/getToken',
      apiUrl: 'openaccess/getApiUrl',
      staffs: 'openaccess/getStaff',
      uiFlags: 'openaccess/getUiFlags',
      records: 'contacts/getContacts',
      workOrder: 'openaccess/getWorkOrder',
      pelangganList: 'openaccess/getPelanggan',
      currentUserID: 'getCurrentUserID',
    }),
    totalCount() {
      return this.meta.total;
    },
    currentPage() {
      return this.meta.currentPage;
    },
    perPage() {
      return this.meta.perPage;
    },
    pageFooterIconClass() {
      return 'margin-left-minus-slab';
    },
    isFooterVisible() {
      return this.totalCount > 0;
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasLastPage() {
      return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
    },
    hasNextPage() {
      const lastPage = Math.ceil(this.totalCount / this.pageSize);
      return this.currentPage < lastPage;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    columns() {
      return [
        {
          field: 'index',
          key: 'index',
          title: 'NO',
          align: 'center',
          width: 50,
          fixed: 'left',
          renderBodyCell: ({ row, rowIndex }) => {
            return this.pageSize * (this.currentPage - 1) + rowIndex + 1;
          },
        },
        {
          field: 'name',
          key: 'name',
          title: 'NAMA JENIS WO',
          align: 'left',
          fixed: 'left',
          width: 150,
          renderBodyCell: ({ row }) => {
            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="wo-type-name">{row.name}</p>
                  <p class="wo-type-description">{row.description}</p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'status',
          key: 'status',
          title: 'STATUS',
          align: 'left',
          width: 120,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                <div class="tags">
                  {row.status === 'pending' && (
                    <span class="pending-tag">Pending</span>
                  )}
                  {row.status === 'active' && (
                    <span class="active-tag">On Progress</span>
                  )}
                  {row.status === 'finish' && (
                    <span class="finish-tag">Finish</span>
                  )}
                  {row.status === 'cancel' && (
                    <span class="cancel-tag">Batal</span>
                  )}
                  {row.status === 'validated' && (
                    <span class="validated-tag">Validated</span>
                  )}
                </div>
              </div>
            </div>
          ),
        },
        {
          field: 'pelanggan',
          key: 'pelanggan',
          title: 'NAMA PELANGGAN',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.pelanggan &&
                row.pelanggan.user &&
                row.pelanggan.user.full_name
                  ? row.pelanggan.user.full_name
                  : '-'}
              </div>
            </div>
          ),
        },
        {
          field: 'staff',
          key: 'staff',
          title: 'STAFF',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.users && row.users.length > 0
                  ? row.users.map(user => user.full_name).join(', ')
                  : 'No Staff Available'}
              </div>
            </div>
          ),
        },
        {
          field: 'type',
          key: 'type',
          title: 'TYPE WO',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => {
            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="wo-type-name">{row.type.type}</p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'start_at',
          key: 'start_at',
          title: 'MULAI',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">{row.start_at}</div>
            </div>
          ),
        },
        {
          field: 'finish_at',
          key: 'finish_at',
          title: 'SELESAI',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">{row.finish_at}</div>
            </div>
          ),
        },
        {
          field: 'estimated_working_time',
          key: 'estimated_working_time',
          title: 'ESTIMASI',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.estimated_working_time === 0
                  ? '-'
                  : `${row.estimated_working_time} menit`}
              </div>
            </div>
          ),
        },
        {
          field: 'action',
          key: 'action',
          title: 'AKSI',
          align: 'center',
          fixed: 'right',
          width: 50,
          renderBodyCell: ({ row }) => (
            <div>
              <router-link
                to={`/app/accounts/${this.$route.params.accountId}/work-orders/${row.id}/edit`}
              >
                Edit
              </router-link>
            </div>
          ),
        },
      ];
    },
  },
  methods: {
    onSearchPelanggan(query) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.$store.dispatch('openaccess/getPelangganList', query);
      }, 500);
    },
    onSelectedWorkOrderType() {
      this.fetchWorkOrders();
    },
    onSelectedWorkOrderMaster() {
      this.fetchWorkOrders();
    },
    onSelectedPelanggan(selectedPelanggan) {
      this.pelanggan_id = selectedPelanggan.pelanggan_id;
      this.fetchWorkOrders();
    },
    onRemovePelanggan() {
      this.pelanggan_id = null;
      this.fetchWorkOrders();
    },
    onRemoveWorkOrderType() {
      this.selectedWorkOrderType = null;
      this.fetchWorkOrders();
    },
    onRemoveWorkOrderMaster() {
      this.selectedWorkOrderMaster = null;
      this.fetchWorkOrders();
    },
    onSelectedStatus() {
      this.fetchWorkOrders();
    },
    onRemoveStatus() {
      this.selectedStatus = null;
      this.fetchWorkOrders();
    },
    addWorkOrder() {
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    onFirstPage() {
      if (!this.hasFirstPage) {
        this.fetchWorkOrders(1);
      }
    },
    onLastPage() {
      if (!this.hasLastPage) {
        const lastPage = Math.ceil(this.totalCount / this.pageSize);
        this.fetchWorkOrders(lastPage);
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.fetchWorkOrders(this.currentPage + 1);
      }
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.fetchWorkOrders(this.currentPage - 1);
      }
    },
    onChangeRange() {
      this.fetchWorkOrders();
    },
    onStaffChange() {
      this.fetchWorkOrders();
    },
    fetchWorkOrders(page = 1) {
      try {
        const dateRange = this.dateRange.map(date => {
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            return localDate;
        });

        const filters = {
          selectedStatus: this.selectedStatus,
          dateRange: dateRange,
          staffSelected: this.staffSelected,
          pelanggan_id: this.pelanggan_id,
          selectedWorkOrderType: this.selectedWorkOrderType,
          selectedWorkOrderMaster: this.selectedWorkOrderMaster,
        };

        this.$store.dispatch('openaccess/getWorkOrderRecordList', {
          page,
          filters,
        });
      } catch (error) {
        console.error('Error fetching work order data:', error);
      }
    },
    async initPageData() {
      try {
        if (this.token) {
          this.fetchWorkOrders();
          this.$store.dispatch('openaccess/getStaffTeknisiList');
          this.$store.dispatch('openaccess/getPelangganList');
          this.$store.dispatch('openaccess/getWorkOrderType');
          this.$store.dispatch('openaccess/getWorkOrderMaster');
        }
      } catch (error) {
        console.error('Error initializing data', error);
      }
    },
  },
  mounted() {
    this.initPageData();
  },
};
</script>

<style scoped>
.custom-multiselect {
  max-width: 100%;
  white-space: normal;
}

.multiselect__tags {
  display: flex;
  flex-wrap: wrap;
}

.multiselect__tag {
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.conversation-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  width: 100%;
}

.complaint-table {
  margin: 0;
  padding: 0;
  width: 100%;
}

.tags {
  display: flex;
  gap: 0.5rem;
}

.pending-tag {
  @apply bg-yellow-600 text-xs py-0.5 px-3 rounded-md;
  color: black;
}

.active-tag {
  @apply bg-yellow-400 text-xs py-0.5 px-3 rounded-md;
  color: black;
}

.validated-tag {
  @apply bg-green-800 text-white text-xs py-0.5 px-3 rounded-md;
}

.cancel-tag {
  @apply bg-red-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.finish-tag {
  @apply bg-green-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.row-user {
  @apply items-center flex text-left;
}

.row-user .user-thumbnail {
  @apply mr-1;
}

.user-block {
  @apply items-start flex flex-col my-0;
}

.wo-type-description {
  @apply text-slate-400 text-xs;
}

.wo-type-name {
  @apply py-1;
}

.search-wrap .contact-search {
  @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
}
</style>
