<template>
  <div>
    <div
      v-if="type === 'problems'"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-white dark:bg-slate-800 p-4 border border-slate-100 dark:border-slate-700 rounded-md gap-4"
    >
      <div
        v-for="(count, issue) in summary"
        :key="issue"
        class="p-2 rounded-md mb-3"
      >
        <div class="mb-4">
          <span class="text-sm">{{ issue }}</span>
          <div class="flex items-end">
            <div class="font-medium text-xl">{{ count }}</div>
          </div>
        </div>

        <div v-if="metrics[issue] && metrics[issue].length">
          <woot-loading-state
            v-if="isLoading"
            class="text-xs"
            :message="$t('REPORT.LOADING_CHART')"
          />
          <woot-bar
            v-else
            :collection="getCollection('problems', issue)"
            :chart-options="getChartOptions('problems', issue)"
            class="h-72 w-full"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="type === 'customers'"
      class="bg-white dark:bg-slate-800 p-4 rounded-md"
    >
      <woot-loading-state
        v-if="isLoading"
        class="text-xs"
        :message="$t('REPORT.LOADING_CHART')"
      />
      <PieChart
        v-else
        :data="getCollection('customers')"
        :options="getChartOptions('customers')"
      />
    </div>
  </div>
</template>

<script>
import reportMixin from 'dashboard/mixins/reportMixin';
import PieChart from './components/ChartElements/PieChart.vue';

export default {
  mixins: [reportMixin],
  components: { PieChart },
  props: {
    customers: {
      type: Array,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    metrics: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedRange: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },

  methods: {
    setDateRange() {
      const endDate = new Date();
      let startDate = new Date();

      if (
        this.selectedRange === 'custom' &&
        this.dateRange[0] &&
        this.dateRange[1]
      ) {
        startDate = new Date(this.dateRange[0]);
        return this.createDateRange(startDate, new Date(this.dateRange[1]));
      }

      switch (this.selectedRange) {
        case '30':
          startDate.setDate(endDate.getDate() - 29);
          break;
        case '90':
          startDate.setMonth(endDate.getMonth() - 3);
          break;
        case '365':
          startDate.setFullYear(endDate.getFullYear() - 1);
          break;
        default:
          startDate.setDate(endDate.getDate() - 6);
      }

      return this.createDateRange(startDate, endDate);
    },

    getCollection(type, issue = '') {
      if (type === 'problems') {
        const data = this.metrics[issue];
        const dateRange = this.setDateRange();

        const labels = this.getProblemsLabel(dateRange);
        const datasets = this.getProblemsDatasets(issue, dateRange, data);

        return { labels, datasets };

      } else if (type === 'customers') {

        const customerData = this.customers;

        const labels = this.getCustomersLabel(customerData);
        const datasets = this.getCustomersDatasets(customerData);

        return { labels, datasets };
        
      }
    },

    getChartOptions(type, issue = '') {
      if (type === 'problems') {
        return this.barChartOption(issue);
      } else if (type === 'customers') {
        return this.pieChartOption(this.customers);
      }
      return {};
    },
  },
};
</script>
