<template>
  <woot-modal :show.sync="show" :on-close="closeModal" size="medium">
    <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
      <woot-modal-header :header-title="'List Pelanggan'"> </woot-modal-header>
      <div class="mt-4 px-8">
        <div class="flex flex-row mb-2 justify-between items-center">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Cari nama pelanggan..."
            class="border-slate-100 dark:border-slate-600 w-[40%] h-9 mb-0"
          />
          <woot-button
            v-tooltip.right="'Filter'"
            color-scheme="secondary"
            class="mb-0 clear"
            icon="filter"
            @click="toggleFilter"
            >Saring</woot-button
          >
        </div>
        <div class="flex flex-col">
          <div
            v-if="uiFlags.isFetchingPelanggan"
            class="mt-2 flex justify-center items-center"
          >
            <spinner />
          </div>
          <ve-table
            v-else
            :max-height="400"
            scroll-width="10rem"
            row-key-field-name="subscriptionId"
            :columns="columns"
            :table-data="pelangganList"
            :border-around="false"
            :checkbox-option="checkboxOption"
            :row-style-option="getRowStyleOption()"
          />
        </div>
        <div class="mt-4 flex flex-row justify-between">
          <p class="text-white self-start my-4">
            {{ paketBerjalanId.length }} Pelanggan dipilih dari
            {{ pelangganList.length }}
          </p>
          <woot-button color-scheme="primary" @click="confirmSelection">
            Pilih
          </woot-button>
        </div>
      </div>
    </div>
    <woot-modal :show.sync="showFilter" :on-close="closeFilter" size="medium">
      <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
        <woot-modal-header :header-title="'Filter Pelanggan'" />
        <div class="mt-4 px-8">
          <div
            class="p-4 rounded-lg bg-slate-25 dark:bg-slate-900 border border-solid border-slate-50 dark:border-slate-700/50 mb-4"
          >
            <div v-for="(filter, index) in filters" :key="index">
              <div v-if="index > 0" class="flex justify-center">
                <select class="w-[10%]" disabled>
                  <option>DAN</option>
                </select>
              </div>
              <div class="flex flex-row items-center pt-2">
                <select v-model="filter.tag" class="w-[20%]">
                  <option
                    v-for="tag in tagsItem"
                    :key="tag.value"
                    :value="tag.value"
                  >
                    {{ tag.name }}
                  </option>
                </select>
                <select v-model="filter.operator" class="ml-4 w-[20%]">
                  <option
                    v-for="operator in tagsOperator"
                    :key="operator.id"
                    :value="operator.id"
                  >
                    {{ operator.name }}
                  </option>
                </select>
                <!-- <select v-model="filter.value" class="ml-4 w-[50%] mb-0">
                  <option
                    v-for="option in getOptions(filter.tag)"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select> -->
                <div class="ml-4 w-1/2">
                  <multiselect
                    :max-height="160"
                    v-model="filter.value"
                    :options="getOptions(filter.tag)"
                    :multiple="true"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :hide-selected="true"
                    track-by="name"
                    label="name"
                    placeholder="Select"
                  />
                </div>
                <woot-button
                  class="ml-4 mb-2"
                  color-scheme="alert"
                  icon="delete"
                  size="tiny"
                  @click="removeFilter(index)"
                />
              </div>
            </div>
            <woot-button
              class="self-start mt-4"
              color-scheme="success"
              icon="add"
              variant="smooth"
              size="small"
              @click="addFilter"
            >
              Tambah Filter
            </woot-button>
          </div>
          <div class="flex flex-row justify-end">
            <woot-button
              class="clear mr-2"
              color-scheme="primary"
              @click="closeFilter"
            >
              Batalkan
            </woot-button>
            <woot-button
              class="self-start"
              color-scheme="primary"
              @click="applyFilter"
            >
              Terapkan filter
            </woot-button>
          </div>
        </div>
      </div>
    </woot-modal>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import axios from 'axios';

export default {
  components: {
    Spinner,
    VeTable,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [alertMixin],
  data() {
    return {
      searchQuery: '',
      showFilter: false,
      filters: [{ tag: 'ODP', operator: 1, value: '' }],
      checkboxOption: {
        selectedRowChange: ({ selectedRowKeys }) => {
          this.paketBerjalanId = selectedRowKeys;
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          this.paketBerjalanId = selectedRowKeys;
        },
      },
      tagsItem: [
        { name: 'ODP', value: 'ODP' },
        { name: 'PORT OLT', value: 'PORT_OLT' },
        { name: 'PORT ONU', value: 'PORT_ONU' },
        { name: 'OLT', value: 'SVLAN' },
      ],
      tagsOperator: [
        { id: 1, name: 'Sama dengan' },
        { id: 3, name: 'Tidak sama dengan' },
      ],
      svlanItems: [
        { name: 'Sukawati', value: '402' },
        { name: 'Lembeng', value: '403' },
        { name: 'Penatih', value: '404' },
      ],
      odpItems: [],
      oltItems: [],
      onuItems: [],
      paketBerjalanId: [],
      pelangganList: [],
    };
  },
  watch: {
    searchQuery(searchValue) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        // await this.$store.dispatch('zabbixs/getPelangganList', searchValue);
        await this.fetchListPelanggan();
      }, 500);
    },
  },
  computed: {
    ...mapGetters({
      getPelanggans: 'zabbixs/getPelanggan',
      uiFlags: 'zabbixs/getUiFlags',
    }),
    columns() {
      return [
        {
          field: '',
          key: 'checkbox',
          type: 'checkbox',
          title: '',
          width: 7,
          fixed: 'left',
          align: 'center',
        },
        {
          field: 'host',
          key: 'host',
          title: 'NAME',
          width: 40,
          align: 'left',
          renderBodyCell: ({ row }) => (
            <p class="complaint-issue">{row.host}</p>
          ),
        },
      ];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    toggleFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    getRowStyleOption() {
      return {
        clickHighlight: false,
        stripe: false,
      };
    },
    getOptions(tag) {
      switch (tag) {
        case 'ODP':
          return this.odpItems;
        case 'PORT_ONU':
          return this.onuItems;
        case 'SVLAN':
          return this.svlanItems;
        case 'PORT_OLT':
          return this.oltItems;
        default:
          return [];
      }
    },
    addFilter() {
      this.filters.push({ tag: 'ODP', operator: 1, value: '' });
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    async fetchFilterList() {
      try {
        const response = await axios.get('/zabbix/filter', {
          headers: {
            Accept: 'application/json',
          },
        });

        this.odpItems = response.data.odp.map(item => ({
          name: item,
          value: item,
        }));
        this.oltItems = response.data.port_olt.map(item => ({
          name: item,
          value: item,
        }));
        this.onuItems = response.data.port_onu.map(item => ({
          name: item,
          value: item,
        }));
      } catch (error) {
        console.error('Error fetching filter:', error);
      }
    },
    applyFilter() {
      this.showFilter = false;

      const hasFilter = this.filters.some(
        filter =>
          filter.tag && filter.value !== undefined && filter.value !== ''
      );

      if (hasFilter) {
        this.$store
          .dispatch('zabbixs/setFilters', this.filters)
          .then(() => {
            this.fetchListPelanggan();
            this.paketBerjalanId = [];
          })
          .catch(error => {
            console.error('Error setting filters:', error);
          });
      } else {
        this.paketBerjalanId = [];
      }
    },
    confirmSelection() {
      this.$emit('selected-pelanggans', this.paketBerjalanId);
    },
    async fetchListPelanggan() {
      try {
        await this.$store.dispatch(
          'zabbixs/getPelangganList',
          this.searchQuery
        );

        const pelangganData = this.getPelanggans
          .map(item => {
            const subscriptionTag = item.tags.find(
              tag => tag.tag === 'SUBSCRIPTION_ID'
            );
            return {
              ...item,
              subscriptionId: subscriptionTag ? subscriptionTag.value : null,
            };
          })
          .filter(
            item =>
              item.subscriptionId !== null && item.subscriptionId !== undefined
          );

        this.pelangganList = pelangganData;
      } catch (error) {
        console.error('Error fetching pelanggan list:', error);
        this.showAlert(
          'Gagal mengambil data pelanggan. Silakan coba lagi.',
        );
      }
    },
  },
  mounted() {
    this.fetchListPelanggan();
    this.fetchFilterList();
  },
};
</script>
