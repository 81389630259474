<template>
  <div class="flex flex-col md:flex-row justify-between mb-4">
    <div class="md:grid flex flex-col filter-container gap-3 w-full">
      <div class="flex flex-col">
        <p class="text-xs mb-2 font-medium">Pilih Tipe</p>
        <div class="multiselect-wrap--small">
          <multiselect
            v-model="currentSelectedType"
            class="no-margin"
            track-by="value"
            label="label"
            selected-label
            deselect-label=""
            :options="summaryType"
            :searchable="false"
            :allow-empty="false"
            @select="updateTypeChange"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <p class="text-xs mb-2 font-medium">Durasi</p>
        <div class="multiselect-wrap--small">
          <multiselect
            v-model="currentSelectedRange"
            class="no-margin"
            track-by="value"
            label="label"
            selected-label
            deselect-label=""
            :options="dateRangeOptions"
            :searchable="false"
            :allow-empty="false"
            @select="updateRangeChange"
          />
        </div>
      </div>

      <div v-if="currentSelectedRange.value === 'custom'" class="flex flex-col">
        <p class="text-xs mb-2 font-medium">Rentang Tanggal</p>
        <Datepicker
          v-model="currentDateRange"
          range
          type="date"
          format="YYYY-MM-DD"
          :confirm="true"
          :not-auto-close="false"
          confirm-text="Pilih"
          class="w-full h-[46px]"
          placeholder="Pilih Rentang Tanggal"
          @change="updateDateRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker';

export default {
  components: { Datepicker },
  props: {
    summaryType: {
      type: Array,
      required: true,
    },
    dateRangeOptions: {
      type: Array,
      required: true,
    },
    selectedType: {
      type: String,
      required: true,
    },
    selectedRange: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSelectedType: this.summaryType.find(
        item => item.value === this.selectedType
      ),
      currentSelectedRange: this.dateRangeOptions.find(
        item => item.value === this.selectedRange
      ),
      currentDateRange: this.dateRange,
    };
  },
  methods: {
    updateRangeChange(selected) {
      this.currentSelectedRange = selected;

      this.$emit('update-filter', {
        selectedType: this.currentSelectedType.value,
        selectedRange: this.currentSelectedRange.value,
        dateRange: this.currentDateRange,
      });
    },
    updateTypeChange(selected) {
      this.currentSelectedType = selected;

      this.$emit('update-filter', {
        selectedType: this.currentSelectedType.value,
        selectedRange: this.currentSelectedRange.value,
        dateRange: this.currentDateRange,
      });
    },
    updateDateRange(dateRange) {
      this.currentDateRange = dateRange;
      this.$emit('update-filter', {
        selectedType: this.currentSelectedType.value,
        selectedRange: this.currentSelectedRange.value,
        dateRange: this.currentDateRange,
      });
    },
  },
};
</script>

<style scoped>
.filter-container {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
</style>
