<template>
  <div class="bulk-action__container">
    <div class="flex-between">
      <label class="bulk-action__panel flex-between">
        <input
          ref="selectAllCheck"
          type="checkbox"
          class="checkbox"
          :checked="allConversationsSelected"
          :indeterminate.prop="!allConversationsSelected"
          @change="selectAll($event)"
        />
        <span>
          {{
            $t('BULK_ACTION.CONVERSATIONS_SELECTED', {
              conversationCount: conversations.length,
            })
          }}
        </span>
      </label>
      <div class="bulk-action__actions flex-between">
        <woot-button
          v-if="showComplaintsButton"
          v-tooltip="$t('BULK_ACTION.COMPLAINT.COMPLAINT_LABELS')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="clipboard"
          class="margin-right-smaller"
          @click="toggleComplaintModal"
        />
        <woot-button
          v-tooltip="$t('BULK_ACTION.LABELS.ASSIGN_LABELS')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="tag"
          class="margin-right-smaller"
          @click="toggleLabelActions"
        />
        <woot-button
          v-tooltip="$t('BULK_ACTION.UPDATE.CHANGE_STATUS')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="repeat"
          class="margin-right-smaller"
          @click="toggleUpdateActions"
        />
        <woot-button
          v-tooltip="$t('BULK_ACTION.ASSIGN_AGENT_TOOLTIP')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="person-assign"
          class="margin-right-smaller"
          @click="toggleAgentList"
        />
        <woot-button
          v-tooltip="$t('BULK_ACTION.ASSIGN_TEAM_TOOLTIP')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="people-team-add"
          @click="toggleTeamsList"
        />
      </div>
      <transition name="popover-animation">
        <complaint
          v-if="showComplaintModal"
          :conversation-count="conversations.length"
          :conversation="conversations"
          :show="showComplaintModal"
          :selectedInboxes="selectedInboxes"
          @cancel="toggleComplaintModal"
        />
      </transition>
      <transition name="popover-animation">
        <label-actions
          v-if="showLabelActions"
          class="label-actions-box"
          @assign="assignLabels"
          @close="showLabelActions = false"
        />
      </transition>
      <transition name="popover-animation">
        <update-actions
          v-if="showUpdateActions"
          class="update-actions-box"
          :selected-inboxes="selectedInboxes"
          :conversation-count="conversations.length"
          :show-resolve="!showResolvedAction"
          :show-reopen="!showOpenAction"
          :show-snooze="!showSnoozedAction"
          @update="updateConversations"
          @close="showUpdateActions = false"
        />
      </transition>
      <transition name="popover-animation">
        <agent-selector
          v-if="showAgentsList"
          class="agent-actions-box"
          :selected-inboxes="selectedInboxes"
          :conversation-count="conversations.length"
          @select="submit"
          @close="showAgentsList = false"
        />
      </transition>
      <transition name="popover-animation">
        <team-actions
          v-if="showTeamsList"
          class="team-actions-box"
          @assign-team="assignTeam"
          @close="showTeamsList = false"
        />
      </transition>
    </div>
    <div v-if="allConversationsSelected" class="bulk-action__alert">
      {{ $t('BULK_ACTION.ALL_CONVERSATIONS_SELECTED_ALERT') }}
    </div>
  </div>
</template>

<script>
import AgentSelector from './AgentSelector.vue';
import UpdateActions from './UpdateActions.vue';
import LabelActions from './LabelActions.vue';
import TeamActions from './TeamActions.vue';
import Complaint from './Complaint.vue';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import { mapGetters } from 'vuex';

export default {
  components: {
    AgentSelector,
    UpdateActions,
    LabelActions,
    TeamActions,
    Complaint,
  },
  props: {
    conversations: {
      type: Array,
      default: () => [],
    },
    allConversationsSelected: {
      type: Boolean,
      default: false,
    },
    selectedInboxes: {
      type: Array,
      default: () => [],
    },
    showOpenAction: {
      type: Boolean,
      default: false,
    },
    showResolvedAction: {
      type: Boolean,
      default: false,
    },
    showSnoozedAction: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showComplaintModal: false,
      showAgentsList: false,
      showUpdateActions: false,
      showLabelActions: false,
      showTeamsList: false,
      popoverPositions: {},
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showComplaintsButton() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.COMPLAINTS
      );
    },
  },
  methods: {
    selectAll(e) {
      this.$emit('select-all-conversations', e.target.checked);
    },
    submit(agent) {
      this.$emit('assign-agent', agent);
    },
    updateConversations(status) {
      this.$emit('update-conversations', status);
    },
    assignLabels(labels) {
      this.$emit('assign-labels', labels);
    },
    assignTeam(team) {
      this.$emit('assign-team', team);
    },
    resolveConversations() {
      this.$emit('resolve-conversations');
    },
    toggleUpdateActions() {
      this.showUpdateActions = !this.showUpdateActions;
    },
    toggleLabelActions() {
      this.showLabelActions = !this.showLabelActions;
    },
    toggleAgentList() {
      this.showAgentsList = !this.showAgentsList;
    },
    toggleTeamsList() {
      this.showTeamsList = !this.showTeamsList;
    },
    toggleComplaintModal() {
      this.showComplaintModal = !this.showComplaintModal;
    },
    closeDelete() {
      this.showComplaintModal = false;
    },
    currentDate() {
      var formatted = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      return formatted;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
// For RTL direction view
.app-rtl--wrapper {
  .bulk-action__actions {
    ::v-deep .button--only-icon:last-child {
      margin-right: var(--space-smaller);
    }
  }
}

.bulk-action__container {
  @apply p-4 relative border-b border-solid border-slate-100 dark:border-slate-600/70;
}

.bulk-action__panel {
  @apply cursor-pointer;

  span {
    @apply text-xs my-0 mx-1;
  }

  input[type='checkbox'] {
    @apply cursor-pointer m-0;
  }
}

.bulk-action__alert {
  @apply bg-yellow-50 text-yellow-700 rounded text-xs mt-2 py-1 px-2 border border-solid border-yellow-300 dark:border-yellow-300/10 dark:bg-yellow-200/20 dark:text-yellow-400;
}

.popover-animation-enter-active,
.popover-animation-leave-active {
  transition: transform ease-out 0.1s;
}

.popover-animation-enter {
  transform: scale(0.95);
  @apply opacity-0;
}

.popover-animation-enter-to {
  transform: scale(1);
  @apply opacity-100;
}

.popover-animation-leave {
  transform: scale(1);
  @apply opacity-100;
}

.popover-animation-leave-to {
  transform: scale(0.95);
  @apply opacity-0;
}

.label-actions-box {
  --triangle-position: 5.3125rem;
}

.update-actions-box {
  --triangle-position: 3.5rem;
}

.agent-actions-box {
  --triangle-position: 1.75rem;
}

.team-actions-box {
  --triangle-position: 0.125rem;
}
</style>
