export const getters = {
    getPelanggan($state) {
        return $state.pelangganList
    },
    getFilter($state) {
        return $state.filters
    },
    getUiFlags($state) {
        return $state.uiFlags
    },
}