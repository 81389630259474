function findStatusByTabKey(tab) {
    switch (tab) {
        case 'Baru':
            return 2;
        // case 'Proses':
        //     return 3;
        case 'Pending':
            return 4;
        case 'Selesai':
            return 5;
        default:
            return 6;
    }
}

function getAgentName(userId, agentList) {
    if (userId === null) {
        return 'System';
    }

    const agentName = agentList.find(agent => agent.id === userId)?.name;

    // If agent does not exist(removed/deleted), return userId
    return agentName || userId;
}

function auditStatus(id) {
    switch (id) {
        case 3: 
            return 'Proses'
        case 4:
            return 'Pending'
            
        case 5:
            return 'Selesai'
        
        case 6:
            return 'Batal'
    
        default:
            return 'Baru'
    }
}

export const getters = {
    getComplaintsMeta($state) {
        return $state.meta
    },
    getComplaints($state) {
        if (Object.keys($state.records).length == 0) return [];
        return $state.sortOrder.map(orderId => $state.records[orderId]);
    },
    getStatuses($state) {
        return $state.statuses
    },
    getComplaintSummary: $state => $state.summary,
    getComplaintMetrics: $state => $state.metric,
    getComplaintCustomers: $state => $state.customers,
    getSelectedTabIndex: $state => $state.selectedTabIndex,
    getUiFlags: $state => $state.uiFlags,
    getComplaint: $state => $state.record,
    getSelectedComplaints: $state => $state.bulkEditModal.selectedComplaints,
    getSelectedTabIndex2: $state => findStatusByTabKey($state.selectedTabIndex),
    getIssues: $state => $state.issues,
    getAudits: $state => $state.audits,
    getViewableAudits($state, getters, rootState,rootGetters) {
        let agentList = rootGetters['agents/getAgents']

        return $state.audits.map((item, i) => {
            let description = '';

            if (item.audited_changes.hasOwnProperty('status_id')) {
                description = `Perubahan status dari ${auditStatus(item.audited_changes.status_id[0])} menjadi ${auditStatus(item.audited_changes.status_id[1])} oleh ${getAgentName(item.user_id, agentList)}`
            }

            if(item.audited_changes.hasOwnProperty('pending_status')) {
                description = `Perubahan status pending ${item.audited_changes.pending_status[0] || ''} menjadi ${item.audited_changes.pending_status[1] || ''} oleh ${getAgentName(item.user_id, agentList)}`
            }
           
            return {
                description,
                time: item.time_difference_human
            }
        });
    }
}