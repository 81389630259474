export default {
  computed: {
    hostURL() {
      return window.pingtalkConfig.hostURL;
    },
    vapidPublicKey() {
      return window.pingtalkConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.pingtalkConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.pingtalkConfig.isEnterprise === 'true';
    },
  },
};
