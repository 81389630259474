import types from '../../mutation-types';
import axios from 'axios';

export const actions = {
  setFilters: ({ commit }, filters) => {
    commit(types.SET_ZABBIX_FILTERS, filters);
  },

  fetchZabbixConfig: async ({ commit }) => {
    try {
      const response = await axios.get('/open_access/zabbix');
      const { api_url, token } = response.data;

      commit(types.SET_ZABBIX_CONFIG, { api_url, token });
    } catch (error) {
      console.error('Error fetching Zabbix config:', error);
      throw error;
    }
  },

  getPelangganList: async ({ commit, state, dispatch }, searchQuery = '') => {
    commit(types.SET_ZABBIX_UI_FLAG, { isFetchingPelanggan: true });

    try {

      if (!state.zabbixConfig?.api_url || !state.zabbixConfig?.token) {
        await dispatch('fetchZabbixConfig');
      }

      const { api_url, token } = state.zabbixConfig;

      const filters = state.filters.flatMap(filter => {
        if (filter.value.length > 1) {
          return filter.value.map(v => ({
            tag: filter.tag,
            operator: filter.operator,
            value: v.value,
          }));
        } else {
          return [
            {
              tag: filter.tag,
              operator: filter.operator,
              value: filter.value.map(v => v.value).join(','),
            },
          ];
        }
      });

      const response = await axios.post(
        `${api_url}/api_jsonrpc.php`,
        {
          jsonrpc: '2.0',
          method: 'host.get',
          params: {
            selectTags: ['tag', 'value'],
            tags: filters,
            limit: searchQuery || filters.length > 0 ? 0 : 15,
            ...(searchQuery && {
              search: {
                name: [searchQuery],
              },
            }),
          },
          id: 2
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.result;

      commit(types.SET_ZABBIX_PELANGGAN_LIST, data);
      commit(types.SET_ZABBIX_UI_FLAG, { isFetchingPelanggan: false });
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
};
