<template>
  <woot-modal :show.sync="show" :on-close="onCancel" size="medium">
    <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
      <woot-modal-header
        :header-title="`Edit Komplain (${selectedComplaints.length} Komplain)`"
      />
      <div class="mt-4 px-8">
        <div class="gap-2 flex flex-row">
          <!-- <div class="w-[50%]">
            <label>
              <input
                class="checkbox pr-1"
                type="checkbox"
                v-model="prioritySelected"
              />
              Prioritas
            </label>
          </div> -->
          <div class="w-[50%]">
            <label>
              <input
                class="checkbox pr-1"
                type="checkbox"
                v-model="massSelected"
              />
              Massal
            </label>
          </div>
        </div>
        <div v-if="massSelected">
          <div class="w-full py-2">
            <label for="massComplaintInput" class="mb-2">Deskripsi</label>
            <input
              type="text"
              class="text mb-0"
              v-model="massComplaint"
              list="massComplaintOptionsList"
              id="massComplaintInput"
              placeholder="Pilih atau ketik deskripsi komplain massal.."
            />
            <datalist id="massComplaintOptionsList">
              <option
                v-for="option in massComplaintOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </datalist>
          </div>
        </div>
        <div class="gap-2 flex flex-row">
          <div class="w-[100%]">
            <label class="mb-1">Status</label>
            <select class="mb-0" v-model="statusSelected">
              <option disabled value="">Pilih Status</option>
              <option
                v-for="status in statuses"
                :key="status.key"
                :value="status.key"
              >
                {{ status.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="statusSelected === 4" class="gap-2 flex flex-row mt-2">
          <div class="w-full pt-2">
            <select class="mb-0" v-model="confirmSelected">
              <option disabled value="">Pilih...</option>
              <option>Menunggu Konfirmasi Pelanggan</option>
              <option>Menunggu Hasil Teknisi</option>
              <option>Menunggu Hasil NOC</option>
            </select>
          </div>
        </div>
        <div class="gap-2 flex flex-row mt-2">
          <div class="w-[50%]">
            <label class="mb-2">Pilih Masalah</label>
            <select :max-height="160" v-model="issueSelected">
              <option disabled value="">Pilih Masalah</option>
              <option
                v-for="issue in issues"
                :key="issue.key"
                :value="issue.key"
              >
                {{ issue.name }}
              </option>
            </select>
          </div>
          <div class="w-[50%]">
            <label class="mb-2"> Komplain Melalui </label>
            <select :max-height="160" v-model="sourcesSelected">
              <option disabled value="">Pilih Komplain Melalui</option>
              <option
                v-for="source in sources"
                :key="source.key"
                :value="source.key"
              >
                {{ source.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="issueSelected === 2">
          <label class="my-2">Jangka Waktu Mati</label>
          <Datepicker
            v-model="customDateRange"
            range
            type="datetime"
            format="YYYY-MM-DD HH:mm"
            :confirm="true"
            :not-auto-close="false"
            confirm-text="Pilih"
            class="w-full"
            @change="onChangeRange"
          />
        </div>
        <div v-if="issueSelected === 8" class="flex mt-3">
          <div class="w-full">
            <input
              class="text"
              type="text"
              v-model="otherIssue"
              :placeholder="'Masukkan keterangan masalah..'"
            />
          </div>
        </div>
        <div class="gap-2 flex flex-row">
          <div class="w-[50%]">
            <label class="mb-2"> Tanggal Komplain </label>
            <input type="date" v-model="currentDate" class="mb-0 datepicker" />
          </div>
          <div class="w-[50%]">
            <label class="mb-2"> Jam Komplain </label>
            <input type="time" v-model="currentTime" class="mb-0 timepicker" />
          </div>
        </div>
        <div>
          <label class="my-2">Keterangan</label>
          <textarea
            v-model="complaintDesc"
            class="min-h-[5rem]"
            type="text"
            :placeholder="'Masukkan Keterangan'"
          />
        </div>
        <div class="flex flex-col items-center">
          <label class="self-start">Tambah Foto</label>
          <input
            type="file"
            @change="onFileChange"
            multiple
            accept="image/*"
            class="my-3"
          />
        </div>
        <div class="flex flex-row justify-end gap-2 pt-5 px-0 w-full">
          <woot-button type="submit" @click="saveChanges"> Simpan </woot-button>
          <button class="button clear" @click.prevent="onCancel">Batal</button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from 'vue2-datepicker';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      // prioritySelected: false,
      massComplaint: '',
      massComplaintOptions: [],
      massSelected: false,
      statusSelected: '',
      issueSelected: '',
      sourcesSelected: '',
      currentDate: '',
      currentTime: '',
      complaintDesc: '',
      otherIssue: '',
      confirmSelected: '',
      customDateRange: [],
      sources: [],
      issues: [],
      uploadedImages: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedComplaints: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    Datepicker,
  },

  computed: {
    ...mapGetters({
      complaintStatuses: 'complaints/getStatuses',
    }),
    statuses() {
      return this.complaintStatuses
        .filter(status => status.id != 1)
        .map(status => ({
          key: status.id,
          name: status.name,
        }));
    },
  },

  methods: {
    async saveChanges() {
      try {
        let utcStartDate = null;
        let utcEndDate = null;

        if (
          this.customDateRange &&
          this.customDateRange[0] &&
          this.customDateRange[1]
        ) {
          const startDate = new Date(this.customDateRange[0]);
          const endDate = new Date(this.customDateRange[1]);
          utcStartDate = startDate.toISOString();
          utcEndDate = endDate.toISOString();
        }
        const image = JSON.stringify(this.uploadedImages);

        let data = {
          // priority: this.prioritySelected ? 1 : 0,
          to_mass: this.massSelected,
          complaint_ids: this.selectedComplaints,
          complaint_date: this.currentDate ? this.currentDate : null,
          complaint_time: this.currentTime
            ? `${this.currentTime.split('T')}.000Z`
            : null,
          problem_id: this.issueSelected || null,
          status_id: this.statusSelected || null,
          description: this.complaintDesc || null,
          complaint_source_id: this.sourcesSelected || null,
          medias: image,
        };

        if (this.issueSelected === 8) {
          data.other_issue = this.otherIssue;
        }
        if (this.statusSelected === 4) {
          data.pending_status = this.confirmSelected;
        }
        if (this.statusSelected === 5) {
          data.completed_at = new Date().toISOString();
        }
        if (this.customDateRange) {
          data.offline_range_start_date = utcStartDate;
          data.offline_range_end_date = utcEndDate;
        }
        if (this.massSelected) {
          data.mass_complaint_description = this.massComplaint;
        }

        data = Object.fromEntries(
          Object.entries(data).filter(
            ([_, value]) => value !== null && value !== undefined
          )
        );

        await this.$store.dispatch('complaints/update', {
          id: data.complaint_ids,
          data,
        });

        this.$emit('complaint-updated');

        this.showAlert('Berhasil memperbaharui data komplain.');
        this.$emit('complaint-reset');
        this.$emit('cancel');
      } catch (error) {
        console.error('Error updateing complaint', error);
      }
    },
    onCancel() {
      this.$emit('complaint-reset');
      this.$emit('cancel');
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    onChangeRange(value) {
      this.customDateRange = value;
    },
    onFileChange(event) {
      const files = event.target.files;
      this.uploadedImages = [];
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        promises.push(this.convertToBase64(file));
      }

      Promise.all(promises).then(base64Files => {
        this.uploadedImages = base64Files;
      });
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    },
    async fetchIssues() {
      try {
        const response = await axios.get('/problems');
        this.issues = response.data.map(problem => ({
          key: problem.id,
          name: problem.name,
        }));
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    },
    async fetchSources() {
      try {
        const response = await axios.get('/complaint_sources');
        this.sources = response.data.map(source => ({
          key: source.id,
          name: source.name,
        }));
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    },
  },

  mounted() {
    this.$store.dispatch('complaints/fetchStatus');
    this.fetchIssues();
    this.fetchSources();
  },
};
</script>
