import types from "../../mutation-types";
import Vue from 'vue';

export const mutations = {
    [types.SET_ZABBIX_UI_FLAG]($state, data) {
        $state.uiFlags = {
            ...$state.uiFlags,
            ...data,
        };
    },
    [types.SET_ZABBIX_PELANGGAN_LIST]: ($state, data) => {
        Vue.set($state, 'pelangganList', data)
    },
    [types.SET_ZABBIX_PELANGGAN_LIST]: ($state, data) => {
        Vue.set($state, 'pelangganList', data)
    },
    [types.SET_ZABBIX_FILTERS]: ($state, filters) => {
        Vue.set($state, 'filters', filters)
    },
    [types.SET_ZABBIX_CONFIG]: ($state, { api_url, token }) => {
        Vue.set($state, 'zabbixConfig', { api_url, token });
    },
}