<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="arrow-download"
      @click="downloadReport"
    >
      {{ $t('COMPLAINTS.DOWNLOAD_COMPLAINTS_REPORTS') }}
    </woot-button>
    <complaint-report-filters
      :summaryType="summaryType"
      :dateRangeOptions="dateRangeOptions"
      :selectedType="selectedType"
      :selectedRange="selectedRange"
      :dateRange="dateRange"
      @update-filter="updateFilterChange"
    />
    <complaint-report-container
      :type="selectedType"
      :customers="customers"
      :summary="summary"
      :metrics="metrics"
      :selectedRange="selectedRange"
      :date-range="dateRange"
      :isLoading="uiFlags.isFetchingSummary"
    />
  </div>
</template>

<script>
import {
  downloadCsvFile,
  generateFileName,
} from '../../../../helper/downloadHelper';
import ComplaintReportContainer from './ComplaintReportContainer.vue';
import reportMixin from 'dashboard/mixins/reportMixin';
import { mapGetters } from 'vuex';
import ComplaintReportFilters from './components/ComplaintReportFilters.vue';

export default {
  components: {
    ComplaintReportContainer,
    ComplaintReportFilters,
  },
  mixins: [reportMixin],
  data() {
    return {
      dateRangeOptions: [
        { label: '7 hari terakhir', value: '7' },
        { label: '30 hari terakhir', value: '30' },
        { label: '3 bulan terakhir', value: '90' },
        { label: '1 tahun terakhir', value: '365' },
        { label: 'Rentang tanggal custom', value: 'custom' },
      ],
      summaryType: [
        { label: 'Masalah yang sering timbul', value: 'problems' },
        { label: 'Pelanggan yang sering komplain', value: 'customers' },
      ],
      selectedRange: '7',
      selectedType: 'problems',
      dateRange: [],
    };
  },

  computed: {
    ...mapGetters({
      summary: 'complaints/getComplaintSummary',
      metrics: 'complaints/getComplaintMetrics',
      customers: 'complaints/getComplaintCustomers',
      uiFlags: 'complaints/getUiFlags',
    }),
  },

  methods: {
    updateFilterChange({ selectedType, selectedRange, dateRange }) {
      this.selectedType = selectedType;
      this.selectedRange = selectedRange;
      this.dateRange = dateRange;
      this.fetchSummary();
    },

    fetchSummary() {
      const { start_date, end_date } = this.getDateRange();
      this.$store.dispatch('complaints/setComplaintSummary', {
        start_date,
        end_date,
      });
    },

    getDateRange() {
      if (this.selectedRange === 'custom' && this.dateRange.length === 2) {
        return {
          start_date: this.formatDate(this.dateRange[0]),
          end_date: this.formatDate(this.dateRange[1]),
        };
      }

      const days =
        {
          '7': 6,
          '30': 29,
          '90': 89,
          '365': 364,
        }[this.selectedRange] || 6;

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - days);

      return {
        start_date: startDate.toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
      };
    },

    async downloadReport() {
      try {
        const { start_date, end_date } = this.getDateRange();
        const reportData = await this.$store.dispatch(
          'complaints/setComplaintReport',
          {
            start_date,
            end_date,
            type: this.selectedType,
          }
        );

        const types = {
          problems: 'complaints_problems_list',
          customers: 'complaints_customer_list',
        };

        const fileName = generateFileName({
          type: types[this.selectedType],
          to: Math.floor(new Date(end_date).getTime() / 1000),
        });

        downloadCsvFile(fileName, reportData);
      } catch (error) {
        console.error('Error downloading report:', error);
      }
    },
  },

  mounted() {
    this.fetchSummary();
  },
};
</script>
