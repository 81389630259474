import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    pelangganList: [],
    filters: [],
    uiFlags: {
        isFetchingPelanggan: false,
    },
    zabbixConfig: null,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
