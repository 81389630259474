<template>
  <woot-modal :show.sync="isVisible" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col mb-30 pb-6">
      <woot-modal-header header-title="Audits" />
      <spinner v-if="uiFlags.isFetchingAudit" class="mt-4" />
      <div class="px-8" v-else-if="audits.length > 0">
        <table class="min-w-full border border-gray-300">
          <tbody>
            <tr>
              <td style="padding-left: 0; padding-right: 0;">Deskripsi</td>
              <td style="padding-left: 0; padding-right: 0;">Waktu</td>
            </tr>
            <tr v-for="(v, i) in audits" :key="i">
              <td style="padding-left: 0; padding-right: 0;">
                {{ v.description }}
              </td>
              <td style="padding-left: 0; padding-right: 0;">{{ v.time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span class="px-8" v-else>No Data</span>
    </div>
  </woot-modal>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Spinner,
  },
  props: {
    isVisible: {
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      audits: 'complaints/getViewableAudits',
      uiFlags: 'complaints/getUiFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>

<style></style>
