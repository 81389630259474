import ComplaintsAPI from '../../../api/complaints';
import types from '../../mutation-types';

export const actions = {
  get: async ({ commit }, params) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: true });
    commit(types.CLEAR_SELECTED_COMPLAINTS);
    try {
      const {
        data: {
          data: { meta, payload },
        },
      } = await ComplaintsAPI.get(params);

      commit(types.CLEAR_COMPLAINTS);
      commit(types.SET_COMPLAINTS, payload);
      commit(types.SET_COMPLAINTS_META, meta);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    }
  },

  show: async ({ commit }, id) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await ComplaintsAPI.show(id);

      commit(types.CLEAR_COMPLAINTS);
      commit(types.SET_COMPLAINT_RECORD, data);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    }
  },

  create: async ({ commit }, body) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isCreating: true });
    try {
      const response = await ComplaintsAPI.create(body);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isCreating: false });

      return response;
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isCreating: false });
    }
  },

  update: async ({ commit }, { id, data }) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isUpdating: true });
    try {
      await ComplaintsAPI.update(id, data);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isUpdating: false });
    }
  },

  fetchStatus: async ({ commit }) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: true });
    try {
      const { data: statuses } = await ComplaintsAPI.status();

      commit(types.SET_COMPLAINTS_STATUSES, statuses);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetching: false });
    }
  },

  setSelectedTabIndex: async ({ commit }, index) => {
    try {
      commit(types.SET_COMPLAINTS_SELECTED_TAB_INDEX, index);
    } catch (error) {}
  },

  setSelectedComplaints: async ({ commit }, data = []) => {
    commit(types.SET_SELECTED_COMPLAINTS, data);
  },

  resetSelectedComplaints: async ({ commit }) => {
    commit(types.CLEAR_SELECTED_COMPLAINTS);
  },

  addComplaint: async ({ commit }, data) => {
    commit(types.ADD_COMPLAINT, data);
  },

  issues: async ({ commit }) => {
    try {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingIssues: true });

      const response = await axios.get('/problems');

      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingIssues: false });
      commit(types.SET_COMPLAINTS_ISSUES, response.data);
    } catch (e) {}
  },

  audit: async ({ commit }, id) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingAudit: true });
    commit(types.CLEAR_SELECTED_COMPLAINTS);
    try {
      const { data } = await ComplaintsAPI.audit(id);

      commit(types.SET_COMPLAINTS_AUDIT, data);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingAudit: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingAudit: false });
    }
  },

  setComplaintSummary: async ({ commit }, params) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingSummary: true });
    try {
      const response = await ComplaintsAPI.summary(params);

      commit(types.SET_COMPLAINTS_SUMMARY, response.data.summary);
      commit(types.SET_COMPLAINTS_METRIC, response.data.metrics);
      commit(types.SET_COMPLAINTS_CUSTOMERS, response.data.customers);
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingSummary: false });
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isFetchingSummary: false });
    }
  },

  setComplaintReport: async ({ commit }, params) => {
    commit(types.SET_COMPLAINTS_UI_FLAG, { isDownloadingReport: true });
    try {
      const response = await ComplaintsAPI.report(params, { responseType: 'blob' });

      commit(types.SET_COMPLAINTS_UI_FLAG, { isDownloadingReport: false });

      return response.data;
    } catch (error) {
      commit(types.SET_COMPLAINTS_UI_FLAG, { isDownloadingReport: false });
    }
  },
};
