import { mapGetters } from 'vuex';
import { formatTime } from '@chatwoot/utils';
import { format, parseISO } from 'date-fns';

export default {
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
    }),
  },
  methods: {
    calculateTrend(key) {
      if (!this.accountSummary.previous[key]) return 0;
      const diff = this.accountSummary[key] - this.accountSummary.previous[key];
      return Math.round((diff / this.accountSummary.previous[key]) * 100);
    },
    displayMetric(key) {
      if (this.isAverageMetricType(key)) {
        return formatTime(this.accountSummary[key]);
      }
      return Number(this.accountSummary[key] || '').toLocaleString();
    },
    displayInfoText(key) {
      if (this.metrics[this.currentSelection].KEY !== key) {
        return '';
      }
      if (this.isAverageMetricType(key)) {
        const total = this.accountReport.data
          .map(item => item.count)
          .reduce((prev, curr) => prev + curr, 0);
        return `${this.metrics[this.currentSelection].INFO_TEXT} ${total}`;
      }
      return '';
    },
    isAverageMetricType(key) {
      return [
        'avg_first_response_time',
        'avg_resolution_time',
        'reply_time',
      ].includes(key);
    },
    formatDate(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    createDateRange(startDate, endDate) {
      const dateRange = [];
      const days = (endDate - startDate) / (1000 * 3600 * 24);

      for (let i = 0; i <= days; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        dateRange.push(format(date, 'yyyy-MM-dd'));
      }

      return dateRange;
    },
    isValidCustomers(customer) {
      return customer && customer.count !== undefined && customer.name;
    },
    pieChartBackgroundColor() {
      const colors = [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4BC0C0',
        '#9966FF',
        '#FF9F40',
        '#FFB6C1',
        '#E6E6E6',
        '#FF5733',
        '#C70039',
        '#900C3F',
        '#581845',
        '#1D2D50',
        '#5C6BC0',
        '#E91E63',
      ];

      return colors;
    },
    barChartOption(issue) {
      return {
        scales: {
          xAxes: [
            {
              gridLines: { display: false, drawOnChartArea: false },
            },
          ],
          yAxes: [
            {
              gridLines: { display: false, drawOnChartArea: false },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                callback: (value, index, values) => {
                  if (!index || index === values.length - 1) {
                    return value;
                  }
                  return '';
                },
              },
            },
          ],
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: tooltipItem => `${issue}: ${tooltipItem.raw}`,
            },
          },
        },
      };
    },
    titleText(customerData) {
      const total = this.calculateTotal(customerData);
      return `Total ${total} Pelanggan Sering Komplain`;
    },
    pieChartOption(customerData) {
      return {
        title: {
          display: true,
          text: this.titleText(customerData),
          position: 'top',
          fontSize: 12,
          fontColor: '#fff',
          padding: 15,
        },
        legend: {
          align: 'start',
          position: 'right',
          labels: { fontSize: 15 },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: tooltipItem => `${tooltipItem.label}: ${tooltipItem.raw}`,
            },
          },
        },
      };
    },
    getProblemsLabel(dateRange) {
      return dateRange.map(date => format(parseISO(date), 'dd-MMM'));
    },
    getProblemsDatasets(issue, dateRange, data) {
      const dataByDate = data.reduce((acc, item) => {
        acc[item.date] = item.count;
        return acc;
      }, {});

      const datasets = [
        {
          label: issue,
          data: dateRange.map(date => dataByDate[date] || 0),
          backgroundColor: 'rgb(31, 147, 255)',
        },
      ];

      return datasets;
    },
    getCustomersLabel(customerData) {
      const maxItems = 15;
      const labels = [];

      customerData.forEach((customer, index) => {
        if (this.isValidCustomers(customer) && index < maxItems) {
          labels.push(`(${customer.count}) ${customer.name}`);
        }
      });

      return labels;
    },

    calculateTotal(customerData) {
      let total = 0;

      customerData.forEach((customer) => {
        if (this.isValidCustomers(customer)) {
          total += customer.count;
        }
      });

      return total;
    },
    getCustomersDatasets(customerData) {
      const maxItems = 15;
      const data = [];

      customerData.forEach((customer, index) => {
        if (this.isValidCustomers(customer) && index < maxItems) {
          data.push(customer.count);
        }
      });

      return [
        {
          label: 'Customer Count',
          data,
          backgroundColor: this.pieChartBackgroundColor(),
        },
      ];
    },
  },
};
